import { Button } from "@mui/material";
import { Stack } from "@mui/material";
import { Card } from "@mui/material";
import { Container } from "@mui/system";

const ChooseProgramType = ({ programTypes, onProgramTypeChosen }) => {
  //console.log(programTypes);

  function getColor(programType) {
    if (programType.includes("C-CATS")) {
      return "#F56600";
    } else if (programType.includes("Summer Camp")) {
      return "#522C7F";
    } else return "#97A7B7";
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "33%",
        left: "50%",
        transform: "translate(-50%, -33%)",
      }}
    >
      <img
        src={process.env.PUBLIC_URL + "/assets/logos/YLI-CAMPS-black.png"}
        style={{ width: "60%", marginBottom: "15px" }}
      ></img>
      <Card sx={{ width: "90%", padding: "15px" }}>
        <h3>Choose Program Type</h3>
        <Stack direction="column">
          {programTypes.map((programType) => (
            <Button
              sx={{ bgcolor: getColor(programType.name) }}
              key={programType.name}
              variant="contained"
              style={{ marginBottom: "15px" }}
              onClick={() =>
                onProgramTypeChosen(programType.name, programType.year)
              }
            >
              {programType.name}
            </Button>
          ))}
        </Stack>
      </Card>
    </Container>
  );
};

export default ChooseProgramType;
