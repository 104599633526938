import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";

import {
  initialErrors,
  initialForm,
  initialState,
  SESSION_TIME,
} from "./constants";

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTokenValues(state, { payload: jwtToken }) {
      //console.log("setting token to: " + jwtToken);
      const data = jwtDecode(jwtToken);
      // state.token = jwtToken;
      localStorage.setItem("token", jwtToken);
      state.customerId = data.customerId;
      state.tokenExpiration = +data.exp;
      state.needsPasswordChange = data?.needsPasswordChange;
      state.sessionExpiration = new Date().getTime() + SESSION_TIME;
    },
    refreshTokenData(state, { payload: jwtToken }) {
      const data = jwtDecode(jwtToken);
      state.tokenExpiration = data.exp;
      state.customerId = data.customerId;
    },
    refreshSession(state) {
      state.sessionExpiration = new Date().getTime() + SESSION_TIME;
    },
    signOut(state) {
      localStorage.clear();
      state = initialState;
    },
    expiredRedirect(state) {
      localStorage.removeItem("token");
      state = initialState;
    },
    clearTokenExpiration(state) {
      state.tokenExpiration = "";
    },
    clearInputs(state) {
      state.form = initialForm;
      state.errors = initialErrors;
    },
    setNeedsPasswordChange(state) {
      state.needsPasswordChange = true;
    },
    clearNeedsPasswordChange(state) {
      state.needsPasswordChange = false;
    },
    setWantsPasswordChange(state) {
      state.wantsPasswordChange = true;
    },
    clearWantsPasswordChange(state) {
      state.wantsPasswordChange = false;
    },
    resetCustomerId(state) {
      //console.log("resetCustomerId");
      const jwtToken = localStorage.getItem("token");
      const data = jwtDecode(jwtToken);
      state.customerId = data.customerId;
    },
    setMasquerading(state, { payload }) {
      state.masquerading = payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
