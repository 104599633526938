import { Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { regActions } from "../../store/register/slice";
import SelectInput from "../UI/SelectInput";
import TextAreaInput from "../UI/TextAreaInput";
import {
  usePrograms,
  useSession,
  useSessions,
} from "../../store/register/hooks";
import { TYPE_CCATS } from "../../store/account/constants";
import { GRADE_OPTIONS } from "../../helpers/list-options";
import ChoosePaymentType from "./ChoosePaymentType";
import PageHeading from "../UI/PageHeading";
import SecondaryHeading from "../UI/SecondaryHeading";

// form that lets customer register a camper for a program session
const SessionForm = ({
  label,
  session,
  updateSession,
  isExistingCamper = false,
  smallHeading = false,
}) => {
  const dispatch = useDispatch();
  const { form, errors, disabled, camperId } = useSelector(
    (state) => state.register
  );
  const programType = useSelector((state) => state.account.programType);

  const { programs, isFetchingPrograms, isSuccessPrograms } =
    usePrograms(programType);
  const [programOptions, setProgramOptions] = useState([]);

  const { sessions, isFetchingSessions, isSuccessSessions } = useSessions({
    ...form,
    camperId,
  });

  const {
    session: chosenSession,
    isFetchingSession,
    isSuccessSession,
    isErrorSession,
    sessionError,
  } = useSession(form.sessionId);

  useEffect(() => {
    if (isSuccessPrograms) {
      //console.log("Programs success");
      //console.log(programs);
      if (programType === TYPE_CCATS) {
        if (form.grade > 8) {
          //console.log("Grade is greater than 8");
          setProgramOptions(
            programs.filter((program) =>
              program.program_name.includes("High School")
            )
          );
        } else if (form.grade > 6) {
          //console.log("Grade is greater than 6");
          setProgramOptions(
            programs.filter((program) =>
              program.program_name.includes("Middle School")
            )
          );
        }
        //console.log("Grade is: " + form.grade);
      }
    }
  }, [programType, form.grade]);

  // disable session inputs while fetching
  useEffect(() => {
    if (isFetchingPrograms || isFetchingSessions) {
      dispatch(regActions.lockRegForm());
    }
  }, [isFetchingPrograms, isFetchingSessions]);

  useEffect(() => {
    if (isSuccessPrograms) {
      dispatch(regActions.unlockRegForm());
      if (programType !== TYPE_CCATS) {
        setProgramOptions(programs);
      }
    }
  }, [isSuccessPrograms, programs, programType]);

  // enable session inputs on api success
  useEffect(() => {
    if (isSuccessSessions) {
      dispatch(regActions.unlockRegForm());
    }
  }, [isSuccessSessions]);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;
    // update form state value for given input name
    dispatch(regActions.setForm({ name, value }));
  }, []);

  if (isFetchingSession) {
    return null;
  }

  const renderGradeOptions = () => {
    if (programType === TYPE_CCATS && isExistingCamper) {
      return (
        <SelectInput
          name="grade"
          label="Grade"
          value={form.grade}
          onChange={handleChange}
          options={GRADE_OPTIONS}
          required
          error={errors.grade}
        />
      );
    } else return null;
  };

  const renderProgramSelect = () => {
    let label = "Program";
    let value = form.programId;
    if (isFetchingPrograms || !programOptions) {
      label = "Loading Programs...";
      value = "";
    } else if (programOptions.length === 0) {
      label = "No Programs Available";
      value = "";
    }

    return (
      <SelectInput
        name="programId"
        label={label}
        value={value}
        onChange={handleChange}
        options={programOptions || []}
        required
        error={errors.programId}
        disabled={disabled.programId}
      />
    );
  };

  const renderSessionSelect = () => {
    let sessionOptions = [];
    let label = "Session";
    let value = "";

    // no available sessions received from api
    if (isSuccessSessions && sessions.length === 0) {
      // fill session options if editing cart session
      if (session) {
        sessionOptions = [{ ...session }];
        value = session.id;
      } else {
        label = "No Available Sessions";
      }
    } else if (sessions) {
      sessionOptions = sessions;
      value = form.sessionId;
    } else if (isFetchingSessions) {
      sessionOptions = [];
      label = "Loading Sessions...";
    }

    return (
      <SelectInput
        name="sessionId"
        label={label}
        value={value}
        onChange={handleChange}
        options={sessionOptions}
        required
        error={errors.sessionId}
        disabled={disabled.sessionId}
      />
    );
  };

  return (
    <>
      {smallHeading ? (
        <SecondaryHeading text={label} />
      ) : (
        <PageHeading text={label} />
      )}
      {renderGradeOptions()}
      {renderProgramSelect()}
      {renderSessionSelect()}
      <TextAreaInput
        multiline={true}
        rows={2}
        name="roommateReq"
        label="Roommate Request"
        value={form.roommateReq}
        onChange={handleChange}
        disabled={disabled.roommateReq}
      />
      {chosenSession && (
        <ChoosePaymentType
          camperId={camperId}
          session={chosenSession}
          updateSession={updateSession}
        />
      )}
    </>
  );
};

SessionForm.propTypes = {
  label: PropTypes.string.isRequired,
  session: PropTypes.object,
};

export default SessionForm;
