import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./constants";
import { thunkReducers } from "./thunks";

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    changeTab(state, action) {
      state.tab = action.payload;
    },
    clearShouldRefetch(state) {
      state.shouldRefetch = false;
    },
    setProgramType(state, { payload }) {
      if (payload.includes("C-CATS")) {
        //console.log("Set program type: C-CATS");
        state.programType = "C-CATS";
      } else {
        //console.log("Set program type: " + payload);
        state.programType = payload;
      }
    },
    setYear(state, { payload }) {
      state.year = payload;
    },
    setProgramTypeChosen(state, { payload }) {
      //true or false. if true, the user has chosen a program type and the selection page will not show
      state.programTypeChosen = payload;
    },
  },
  extraReducers: thunkReducers,
});

export const accountActions = accountSlice.actions;

export default accountSlice.reducer;
