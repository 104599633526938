import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PAY_DEPOSIT, PAY_FULL } from "../../store/cart/constants";
import { TYPE_CCATS } from "../../store/account/constants";

const ChoosePaymentType = ({ session, updateSession, camperId }) => {
  const [newSession, setNewSession] = useState(session);
  const [fullPaySelected, setFullPaySelected] = useState(
    newSession.payOption === PAY_FULL
  );
  const [totalDiscount, setTotalDiscount] = useState(0);

  const {programType} = useSelector((state) => state.account);

  useEffect(() => updateDiscounts(), []);

  useEffect(() => {
    updateDiscounts();
  }, [session, fullPaySelected]);

  function updatePaymentType(event) {
    let type = event.target.value;
    setNewSession((prevState) => {
      let updatedSession = { ...prevState };
      updatedSession.payOption = type;
      updateSession(updatedSession);
      return updatedSession;
    });
    if (type === PAY_FULL) setFullPaySelected(true);
    else setFullPaySelected(false);
  }

  function updateDiscounts() {
    setNewSession((prevState) => {
      let updatedSession = { ...prevState };
      let discounts = [];
      let total = 0;
      if (camperId) {
        discounts.push({
          amount: session.returnDiscount,
          name: "returning camper discount",
        });
        total += session.returnDiscount;
      }
      // early bird discount
      if (isBeforeEbdCutoff(session.ebdCutoff)) {
        discounts.push({
          amount: session.ebdAmount,
          name: "early bird discount",
        });
        total += session.ebdAmount;
      }
      // special discount
      // if (reg.specialDiscount > 0) {
      //   reg.discounts.special = reg.specialDiscount;
      // }
      updatedSession.discounts = discounts;
      setTotalDiscount(total);
      updateSession(updatedSession);
      //console.log("Updated session: ", updatedSession);
      return updatedSession;
    });
  }

  const isBeforeEbdCutoff = (ebdCutoff) => {
    const currentDate = new Date();
    try {
      // ensure timezone does not interfere with date comparison
      const ebdCutoffDate = new Date(
        new Date(ebdCutoff).toISOString().slice(0, -1)
      );
      // ebd is valid until the day after cutoff date
      ebdCutoffDate.setDate(ebdCutoffDate.getDate() + 1);
      return currentDate < ebdCutoffDate;
    } catch (e) {
      //console.log(ebdCutoff);
      return false;
    }
  };

  return (
    <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
      <Stack direction="column" sx={{ width: "100%" }}>
        <Typography variant="h7" sx={{ fontWeight: "bold" }}>
          Choose Payment Type
        </Typography>
        <RadioGroup
          name={`payment-option-${session.id}`}
          onChange={updatePaymentType}
        >
          <FormControlLabel
            value={PAY_FULL}
            control={
              <Radio size="small" sx={{ p: "6px" }} checked={fullPaySelected} />
            }
            label={
              <Typography variant="caption">
                Pay in Full - ${session.totalCost}
              </Typography>
            }
            sx={{ m: 0 }}
          />
          <FormControlLabel
            value={PAY_DEPOSIT}
            control={
              <Radio
                size="small"
                sx={{ p: "6px" }}
                checked={!fullPaySelected}
              />
            }
            label={
              <Typography variant="caption">
                Pay deposit - ${session.depositAmount}
              </Typography>
            }
            sx={{ m: 0 }}
          />
        </RadioGroup>
      </Stack>
      { programType!==TYPE_CCATS &&
      <Box
        sx={{
          border: "1px solid #D1D5D8",
          borderRadius: "2px",
          backgroundColor: "#ECEEEF",
          padding: "0.5rem",
          width: "100%",
        }}
      >
        <Stack direction="column">
          <Typography sx={{ fontWeight: "bold", marginLeft: "11px" }}>
            ${fullPaySelected ? session.totalCost : session.depositAmount}
          </Typography>
          {totalDiscount > 0 &&
            newSession.discounts.map((discount) => (
              <Typography key={discount.name}>
                - ${discount.amount} {discount.name}
              </Typography>
            ))}
          <Divider />
          <Typography
            color="#AC4E1E"
            sx={{ marginLeft: "11px", fontWeight: "bold" }}
          >
            $
            {fullPaySelected
              ? newSession.totalCost - totalDiscount
              : newSession.depositAmount}{" "}
            total
          </Typography>
          <Typography variant="caption" sx={{ marginLeft: "11px" }}>
            {!fullPaySelected && totalDiscount > 0
              ? "(discounts will be applied to your remaining balance)"
              : ""}
          </Typography>
        </Stack>
      </Box>
      }
    </Stack>
  );
};

export default ChoosePaymentType;
