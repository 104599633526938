import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cartActions } from "../../store/cart/slice";
import { regActions } from "../../store/register/slice";
import CamperForm from "./CamperForm";
import SessionForm from "./SessionForm";
import useValidation from "../../hooks/useValidation";
import {
  editSummerCamperValidations,
  editRegValidations,
  newSummerCamperValidations,
  newRegValidations,
  priorSummerCamperValidations,
  priorCcatsCamperValidations,
  newCcatsCamperValidations,
  editCcatsCamperValidations,
} from "../../helpers/validations";
import {
  CHOOSE_REGISTER_ACTION,
  CONFIRM_PERMISSIONS,
  EDIT_CAMPER,
  EDIT_REG,
  NEW_CAMPER,
  NEW_REG,
  NEW_REG_PRIOR_CAMPER,
} from "../../store/register/constants";
import { usePriorCampers, useSession } from "../../store/register/hooks";

import { Button, Stack } from "@mui/material";
import Loader from "../UI/Loader";
import { useSearchParams } from "react-router-dom";
import { uiActions } from "../../store/ui/slice";
import { REGISTER_STEP } from "../../store/ui/constants";
import Permissions from "./Permissions";
import { useNavigate } from "react-router-dom";
import { TYPE_CCATS } from "../../store/account/constants";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import CamperItem from "./CamperItem";
import { Box } from "@mui/system";
import { Divider } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Card } from "@mui/material";
import PageHeading from "../UI/PageHeading";
import AddButton from "../UI/AddButton";

// container for all components that handle registration actions
const RegistrationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { currentAction, form, camperKey, regKey, camperId } = useSelector(
    (state) => state.register
  );
  const { customerId } = useSelector((state) => state.auth);
  const { campers, registrations, digitalSignature } = useSelector(
    (state) => state.cart
  );
  const programType = useSelector((state) => state.account.programType);

  const camper = campers.find((c) => c.key === camperKey);
  const { priorCampers, isFetchingPriorCampers, isSuccessPriorCampers } =
    usePriorCampers({ customerId, programType });

  const [chosenSession, setChosenSession] = useState();

  const {
    session,
    isFetchingSession,
    isSuccessSession,
    isErrorSession,
    sessionError,
  } = useSession(form.sessionId);

  const [params, setParams] = useSearchParams();

  const location = useLocation();
  const redirectPath = location.pathname.includes("/ccats")
    ? "/register/ccats"
    : "/register";

  useEffect(() => {
    //remove any campers that don't have registrations
    campers.forEach((camper) => {
      if (
        registrations.filter((reg) => reg.camperKey === camper.key).length === 0
      ) {
        dispatch(cartActions.deleteCamper(camper.key));
        dispatch(cartActions.updateRemainingBalances());
        dispatch(cartActions.updatePayAmounts(programType));
      }
    });
  }, [registrations]);

  // set active step for stepper
  useEffect(() => {
    if (digitalSignature.length === 0) {
      dispatch(regActions.setupDigitalConfirmation());
    } else {
      dispatch(uiActions.setActiveRegisterStep(REGISTER_STEP));
    }
  }, []);

  useEffect(() => {
    if (isSuccessSession) setChosenSession(session);
  }, [isSuccessSession, session]);

  useEffect(() => {
    if (
      currentAction !== CHOOSE_REGISTER_ACTION &&
      currentAction !== CONFIRM_PERMISSIONS
    ) {
      dispatch(
        uiActions.setBackAction(() => {
          if (params.has("camper")) {
            params.delete("camper");
            setParams(params);
          }
          dispatch(regActions.setupChooseRegisterAction());
        })
      );
      dispatch(uiActions.setBackText("BACK"));
    } else {
      dispatch(uiActions.setBackAction(() => navigate("/account/dashboard")));
      dispatch(uiActions.setBackText("DASHBOARD"));
    }
  }, [currentAction]);

  //check if the user has been redirected from the dashboard
  useEffect(() => {
    if (digitalSignature.length === 0 || !isSuccessPriorCampers) {
      // don't execute unless terms and conditions have been signed
      // and prior campers have been fetched successfully
      return;
    }

    const camperId = +params.get("camper");
    const camper = campers.find((c) => c.id === camperId);
    const priorCamper = priorCampers.find((c) => c.id === camperId);

    if (camper) {
      dispatch(regActions.setupNewRegistration(camper));
    } else if (priorCamper) {
      dispatch(regActions.setupPriorCamperRegistration(priorCamper));
    } else if (camperId) {
      dispatch(regActions.setupChooseRegisterAction());
      dispatch(uiActions.setAlertError("Camper not found"));
    }
  }, [params, campers, priorCampers, isSuccessPriorCampers]);

  let priorCamperValidations = priorSummerCamperValidations;
  let newCamperValidations = newSummerCamperValidations;
  let editCamperValidations = editSummerCamperValidations;
  if (programType === TYPE_CCATS) {
    priorCamperValidations = priorCcatsCamperValidations;
    newCamperValidations = newCcatsCamperValidations;
    editCamperValidations = editCcatsCamperValidations;
  }

  useEffect(() => {
    //console.log("priorCampers", priorCampers);
    if (
      !isFetchingPriorCampers &&
      isSuccessPriorCampers &&
      priorCampers.length === 0 &&
      campers.length === 0
    ) {
      dispatch(regActions.setupNewCamper());
    }
  }, [isFetchingPriorCampers, isSuccessPriorCampers, priorCampers, campers]);

  // form validators
  const validatePriorCamper = useValidation(
    priorCamperValidations,
    regActions.setInputError
  );
  const validateNewCamper = useValidation(
    newCamperValidations,
    regActions.setInputError
  );
  const validateNewReg = useValidation(
    newRegValidations,
    regActions.setInputError
  );
  const validateUpdateCamper = useValidation(
    editCamperValidations,
    regActions.setInputError
  );
  const validateUpdateReg = useValidation(
    editRegValidations,
    regActions.setInputError
  );

  if (isFetchingSession) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );
  }

  // if (isSuccessSession) {
  //   //console.log("Got session", session);
  // }

  // if (isErrorSession) {
  //   console.log("Error getting session");
  //   console.log(sessionError);
  // }

  // setup page for adding a camper and registration into the cart
  const setupNewCamper = () => {
    dispatch(regActions.setupNewCamper());
  };

  // setup page for adding a new registration into the cart
  const setupNewRegistration = (camper) => {
    dispatch(regActions.setupNewRegistration(camper));
  };

  // setup page for adding customer's prior camper and a new registration into the cart
  const setupPriorCamper = (camper) => {
    dispatch(regActions.setupPriorCamperRegistration(camper));
  };

  // update information for a camper in the cart
  const updateCamper = () => {
    if (validateUpdateCamper(form)) {
      const { firstName, lastName, birthdate, grade, sex, shirtSize } = form;
      const camperData = {
        camperKey,
        firstName,
        lastName,
        birthdate,
        grade,
        sex,
        shirtSize,
      };

      dispatch(cartActions.updateCamper(camperData));
      dispatch(regActions.setupChooseRegisterAction());
      dispatch(uiActions.setAlertInfo("Camper updated"));
    }
  };

  // update information for a camper's registration in the cart
  const handleUpdateRegistration = () => {
    //console.log("Updating registration");
    //console.log("Session: ", session);
    //console.log("isFetching: ", isFetchingSession);
    if (validateUpdateReg(form)) {
      const { programId, sessionId, roommateReq } = form;
      const regData = {
        ...chosenSession,
        camperKey,
        key: regKey,
        programId,
        sessionId,
        roommateReq,
      };
      dispatch(cartActions.updateRegistration(regData));
      dispatch(regActions.setupChooseRegisterAction());
      dispatch(uiActions.setAlertInfo("Registration updated"));
    }
  };

  function updateSession(session) {
    setChosenSession(session);
  }

  // add camper and registration info from the form into the cart
  const addCamperToCart = () => {
    //console.log("form", form);
    //console.log("session", session);
    if (validateNewCamper(form)) {
      const {
        firstName,
        lastName,
        birthdate,
        grade,
        sex,
        shirtSize,
        programId,
        sessionId,
        roommateReq,
      } = form;
      const camperData = {
        camperKey,
        firstName,
        lastName,
        grade,
        birthdate,
        sex,
        shirtSize,
      };
      const regData = {
        ...chosenSession,
        camperKey,
        key: regKey,
        programId,
        sessionId,
        roommateReq,
      };
      dispatch(cartActions.addCamper(camperData));
      dispatch(cartActions.addRegistration(regData));
      dispatch(regActions.setupChooseRegisterAction());
      dispatch(uiActions.setAlertInfo("Registration added to cart"));
      navigate(redirectPath);
    }
  };

  // add customer's prior camper and new registration from the form into the cart
  const addPriorCamperToCart = () => {
    if (validatePriorCamper(form)) {
      const {
        firstName,
        lastName,
        birthdate,
        grade,
        sex,
        shirtSize,
        programId,
        sessionId,
        roommateReq,
      } = form;
      const camperData = {
        camperKey,
        camperId,
        firstName,
        lastName,
        birthdate,
        grade,
        sex,
        shirtSize,
      };
      const regData = {
        ...chosenSession,
        camperKey,
        key: regKey,
        programId,
        sessionId,
        roommateReq,
      };
      dispatch(cartActions.addPriorCamper(camperData));
      dispatch(cartActions.addRegistration(regData));
      dispatch(regActions.setupChooseRegisterAction());
      dispatch(uiActions.setAlertInfo("Registration added to cart"));
      navigate(redirectPath);
    }
  };

  // add new registration for the selected camper into the cart
  const addRegistrationToCart = () => {
    //console.log("Adding session", chosenSession);
    if (validateNewReg(form)) {
      const { programId, sessionId, roommateReq } = form;
      const regData = {
        ...chosenSession,
        camperKey,
        key: regKey,
        programId,
        sessionId,
        roommateReq,
      };
      const camperRegistrations = registrations.filter(
        (r) => r.camperKey === camperKey
      );
      if (camperRegistrations.filter((r) => r.id === regData.id).length === 0) {
        dispatch(cartActions.addRegistration(regData));
        dispatch(regActions.setupChooseRegisterAction());
        dispatch(uiActions.setAlertInfo("Registration added to cart"));
      } else {
        dispatch(
          uiActions.setAlertError(
            "Camper is already registered for that session"
          )
        );
      }
    }
  };

  // render forms for adding a new camper and new registration into the cart
  const renderNewCamper = () => {
    return (
      <>
        <CamperForm
          label={`Register a ${
            programType.includes(TYPE_CCATS) ? "Student" : "Camper"
          }`}
        />
        <SessionForm
          label="Pick a Program"
          smallHeading
          updateSession={updateSession}
        />
        <Button variant="contained" onClick={addCamperToCart} fullWidth>
          Add to Cart
        </Button>
      </>
    );
  };

  // render forms for adding a new registration for a prior camper into the cart
  const renderPriorCamper = () => {
    return (
      <>
        <CamperForm
          label={`${
            programType.includes(TYPE_CCATS) ? "Student" : "Camper"
          } Info`}
        />
        <SessionForm
          label="Add Registration"
          smallHeading
          updateSession={updateSession}
        />
        <Button variant="contained" onClick={addPriorCamperToCart} fullWidth>
          Add to Cart
        </Button>
      </>
    );
  };

  // render form for adding a new registration to a camper in the cart
  const renderNewRegistration = () => {
    return (
      <>
        <SessionForm
          label={`Add Registration - ${camper.firstName} ${camper.lastName}`}
          isExistingCamper={true}
          updateSession={updateSession}
        />
        <Button variant="contained" onClick={addRegistrationToCart} fullWidth>
          Add to Cart
        </Button>
      </>
    );
  };

  // render form for editing a camper in the cart
  const renderEditCamper = () => {
    return (
      <>
        <CamperForm label="Edit Camper" />
        <Button variant="contained" onClick={updateCamper} fullWidth>
          Update Camper
        </Button>
      </>
    );
  };

  // render form for editing a registration in the cart
  const renderEditRegistration = () => {
    return (
      <>
        <SessionForm
          label="Edit Registration"
          session={session}
          isExistingCamper={true}
          updateSession={updateSession}
        />
        <Button
          variant="contained"
          onClick={handleUpdateRegistration}
          fullWidth
        >
          Update Registration
        </Button>
      </>
    );
  };

  function renderRegistrationBox() {
    if (isFetchingPriorCampers) {
      return <Loader />;
    }
    return (
      <Fragment>
        <PageHeading
          text={`Register ${
            programType.includes(TYPE_CCATS) ? "Students" : "Campers"
          }`}
        />
        {renderRegistrationItems()}
        {priorCampers.map((pCamper) => {
          //don't show this camper if they already have a registration in the cart
          if (!campers.find((c) => c.id === pCamper.id))
            return (
              <AddButton
                key={pCamper.id}
                onClick={() =>
                  dispatch(regActions.setupPriorCamperRegistration(pCamper))
                }
              >
                {`Add Registration for ${pCamper.firstName} ${pCamper.lastName}`}
              </AddButton>
            );
        })}
        <AddButton onClick={setupNewCamper}>
          {registrations.filter((reg) => reg.programType.includes(programType))
            .length > 0
            ? `Register another ${
                programType.includes(TYPE_CCATS) ? "student" : "camper"
              }`
            : `Add a new ${
                programType.includes(TYPE_CCATS) ? "student" : "camper"
              }`}
        </AddButton>
        <Divider />
        <Button
          variant="contained"
          onClick={() => navigate("/checkout")}
          sx={{ height: "50px", width: "100%" }}
        >
          Next
        </Button>
      </Fragment>
    );
  }

  function setupEditCamper(camper) {
    dispatch(regActions.setupEditCamper(camper));
  }

  function renderRegistrationItems() {
    let programTypeCampers = campers.filter((camper) => {
      let hasProgramType = false;
      registrations.forEach((reg) => {
        if (reg.camperKey === camper.key) {
          if (reg.programType.includes(programType)) {
            hasProgramType = true;
          }
        }
      });
      return hasProgramType;
    });
    return programTypeCampers.map((camper) => (
      <CamperItem
        key={camper.key}
        camper={camper}
        onAddCamp={setupNewRegistration}
        editCamper={setupEditCamper}
      />
    ));
  }

  // render form for collecting digitalSignature and digitalConfirmed (permissions)
  const renderConfirmPermissions = () => {
    return <Permissions />;
  };

  // select the content to render based on customer's selection
  const renderContent = () => {
    switch (currentAction) {
      case CHOOSE_REGISTER_ACTION:
        return renderRegistrationBox();
      case NEW_CAMPER:
        return renderNewCamper();
      case NEW_REG:
        return renderNewRegistration();
      case NEW_REG_PRIOR_CAMPER:
        return renderPriorCamper();
      case EDIT_CAMPER:
        return renderEditCamper();
      case EDIT_REG:
        return renderEditRegistration();
      case CONFIRM_PERMISSIONS:
        return renderConfirmPermissions();
      default:
        return renderRegistrationBox();
    }
  };

  return (
    <Stack spacing={2} justifyContent="center" alignItems="center">
      {renderContent()}
    </Stack>
  );
};

export default RegistrationPage;
