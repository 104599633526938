import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (build) => ({
    signUp: build.mutation({
      query: (credentials) => ({
        url: "signup",
        method: "POST",
        body: credentials,
      }),
    }),
    signIn: build.mutation({
      query: (credentials) => ({
        url: "signin",
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: build.mutation({
      query: (email) => ({
        url: "forgot_password",
        method: "POST",
        body: email,
      }),
    }),
    updatePassword: build.mutation({
      query: ({ password, confirmPassword }) => ({
        url: "update_password",
        method: "POST",
        body: { password, confirmPassword },
      }),
    }),
    validateToken: build.mutation({
      query: (token) => ({
        url: "validate_token",
        method: "POST",
        body: { token },
      }),
    }),
    refreshToken: build.mutation({
      query: () => {
        const token = localStorage.getItem("token");
        return {
          url: `refresh_token`,
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
        };
      },
      transformResponse: (response) => {
        //console.log("received new token: " + response);
        return response;
      },
      async onQueryStarted(expiration) {
        //console.log("refreshing token");
      },
    }),
  }),
});

export const {
  useSignUpMutation,
  useSignInMutation,
  useForgotPasswordMutation,
  useUpdatePasswordMutation,
  useValidateTokenMutation,
  useRefreshTokenMutation,
} = authApi;
