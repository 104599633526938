import { useEffect } from "react";
import { useSelector } from "react-redux";
import { TYPE_CCATS } from "../account/constants";
import {
  useGetPermissionsQuery,
  useGetPriorCampersQuery,
  useGetProgramQuery,
  useGetProgramsQuery,
  useGetProgramTypeQuery,
  useGetSessionQuery,
  useGetSessionsQuery,
} from "./api";

const REFETCH_DELAY = 3000;

export const usePriorCampers = (customerId) => {
  const skip = !customerId;
  const {
    data: priorCampers,
    error: priorCampersError,
    isFetching: isFetchingPriorCampers,
    isSuccess: isSuccessPriorCampers,
    isError: isErrorPriorCampers,
    refetch: refetchPriorCampers,
  } = useGetPriorCampersQuery(customerId, { skip });

  useEffect(() => {
    if (isErrorPriorCampers) {
      console.error(priorCampersError);
      setTimeout(refetchPriorCampers, REFETCH_DELAY);
    }
  }, [isErrorPriorCampers]);

  return {
    priorCampers,
    priorCampersError,
    isFetchingPriorCampers,
    isSuccessPriorCampers,
    isErrorPriorCampers,
  };
};

export const useProgram = (programId) => {
  const skip = !programId;
  const {
    data: program,
    error: programError,
    isFetching: isFetchingProgram,
    isSuccess: isSuccessProgram,
    isError: isErrorProgram,
    refetch: refetchProgram,
  } = useGetProgramQuery(programId, { skip });

  useEffect(() => {
    if (isErrorProgram) {
      console.error(programError);
      setTimeout(refetchProgram, REFETCH_DELAY);
    }
  }, [isErrorProgram]);

  return {
    program,
    programError,
    isFetchingProgram,
    isSuccessProgram,
    isErrorProgram,
  };
};

export const usePermissions = () => {
  const {
    data: permissions,
    error: permissionsError,
    isFetching: isFetchingPermissions,
    isError: isErrorPermissions,
    isSuccess: isSuccessPermissions,
    refetch: refetchPermissions,
  } = useGetPermissionsQuery();

  useEffect(() => {
    if (isErrorPermissions) {
      console.error(permissionsError);
      setTimeout(refetchPermissions, REFETCH_DELAY);
    }
  }, [isErrorPermissions]);

  return {
    permissions,
    permissionsError,
    isFetchingPermissions,
    isErrorPermissions,
    isSuccessPermissions,
  };
};

export const usePrograms = (programType) => {
  const {
    data: programs,
    error: programsError,
    isFetching: isFetchingPrograms,
    isSuccess: isSuccessPrograms,
    isError: isErrorPrograms,
    refetch: refetchPrograms,
  } = useGetProgramsQuery(programType);

  useEffect(() => {
    if (isErrorPrograms) {
      console.error(programsError);
      setTimeout(refetchPrograms, REFETCH_DELAY);
    }
  }, [isErrorPrograms]);

  return {
    programs,
    programsError,
    isFetchingPrograms,
    isSuccessPrograms,
    isErrorPrograms,
  };
};

export const useSession = (sessionId) => {
  const skip = !sessionId;
  //console.log("useSession", sessionId);

  const {
    data: session,
    error: sessionError,
    isFetching: isFetchingSession,
    isSuccess: isSuccessSession,
    isError: isErrorSession,
    refetch: refetchSession,
  } = useGetSessionQuery(sessionId, { skip });

  useEffect(() => {
    if (isErrorSession) {
      console.error(sessionError);
      setTimeout(refetchSession, REFETCH_DELAY);
    }
  }, [isErrorSession]);

  return {
    session,
    sessionError,
    isFetchingSession,
    isSuccessSession,
    isErrorSession,
  };
};

export const useSessions = ({ programId, birthdate, grade, sex, camperId }) => {
  const programType = useSelector((state) => state.account.programType);

  let skip = false;
  if (programType === TYPE_CCATS) {
    skip = !(programId && grade && sex);
  } else {
    // Summer Camp by default
    skip = !(programId && birthdate && sex);
    if (
      birthdate &&
      typeof birthdate === "object" &&
      birthdate !== "Invalid Date"
    ) {
      birthdate = birthdate.toISOString().slice(0, -1);
    }
  }

  const {
    data: sessions,
    error: sessionsError,
    isFetching: isFetchingSessions,
    isSuccess: isSuccessSessions,
    isError: isErrorSessions,
    refetch: refetchSessions,
  } = useGetSessionsQuery(
    { programId, birthdate, grade, sex, camperId },
    { skip }
  );

  useEffect(() => {
    if (isErrorSessions) {
      console.error(sessionsError);
      setTimeout(refetchSessions, REFETCH_DELAY);
    }
  }, [isErrorSessions]);

  return {
    sessions,
    sessionsError,
    isFetchingSessions,
    isSuccessSessions,
    isErrorSessions,
  };
};

export const useProgramType = (name) => {
  const skip = name.length === 0;

  const {
    data: programType,
    error: programTypeError,
    isFetching: isFetchingProgramType,
    isSuccess: isSuccessProgramType,
    isError: isErrorProgramType,
    refetch: refetchProgramType,
  } = useGetProgramTypeQuery(name, { skip });

  useEffect(() => {
    if (isErrorProgramType) {
      console.error(programTypeError);
      setTimeout(refetchProgramType, REFETCH_DELAY);
    }
  }, [isErrorProgramType]);

  return {
    programType,
    programTypeError,
    isFetchingProgramType,
    isSuccessProgramType,
    isErrorProgramType,
  };
};
