import { Routes, Route, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { tabsToArray, TYPE_CCATS } from "./store/account/constants";
import AccountPage from "./components/Account/AccountPage";
import { SIGN_IN, SIGN_UP, FORGOT_PASSWORD } from "./store/auth/constants";
import ProtectedRoute from "./components/ProtectedRoute";
import RegistrationPage from "./components/Registration/RegistrationPage";
import AuthenticationPage from "./components/Authentication/AuthenticationPage";
import CheckoutPage from "./components/Checkout/CheckoutPage";
import { theme, cCatsTheme } from "./styles";
import {
  ACCOUNT_LAYOUT,
  CART_LAYOUT,
  DEFAULT_LAYOUT,
} from "./store/ui/constants";

import { ThemeProvider } from "@mui/material";
import Masquerade from "./components/Account/Masquerade";
import ReceiptPage from "./components/Account/Receipts/RecieptPage";
import PageNotFound from "./components/PageNotFound";
import DefaultLayout from "./components/UI/DefaultLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorActions } from "./store/error/slice";
import { Container } from "@mui/system";
import BugReportDialog from "./components/UI/BugReportDialog";
import EditAccountPage from "./components/Account/Edit/EditAccountPage";
import AccountLayout from "./components/UI/AccountLayout";

function App() {
  const programType = useSelector((state) => state.account.programType);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bugDialogOpen, setBugDialogOpen] = useState(false);
  const [showReportButton, setShowReportButton] = useState(true);

  useEffect(() => {
    document.title = "YLI Registrations";
  }, []);

  const handleFallback = ({ error, resetErrorBoundary }) => {
    //console.log("handleFallBack");
    dispatch(
      errorActions.setError(
        "[" +
          new Date().toLocaleString("en-US", { timeZone: "EST" }) +
          "]\n" +
          "Route: " +
          window.location.pathname +
          "\n" +
          error.stack
      )
    );
    return (
      <Container
        maxWidth="md"
        sx={{
          bgcolor: "white",
          paddingTop: "4px",
          paddingBottom: "16px",
          marginTop: "10px",
        }}
      >
        <p>
          Something went wrong! We apologize for the inconvenience. Please
          submit a bug report to help us fix this issue.
        </p>
        <button onClick={resetErrorBoundary}>Try again</button>
        {showReportButton ? (
          <button onClick={() => setBugDialogOpen(true)}>Report Bug</button>
        ) : (
          <p>Thank you for submitting a bug report!</p>
        )}
      </Container>
    );
  };

  function removeBugReportButton() {
    setShowReportButton(false);
    dispatch(errorActions.setError(""));
  }

  function closeBugDialog() {
    setBugDialogOpen(false);
  }

  const handleReset = () => {
    navigate(window.location.pathname);
  };

  return (
    <ThemeProvider theme={programType === "C-CATS" ? cCatsTheme : theme}>
      <BugReportDialog
        open={bugDialogOpen}
        closeDialog={closeBugDialog}
        onSubmit={removeBugReportButton}
        inErrorBoundary={true}
      />
      <ErrorBoundary FallbackComponent={handleFallback} onReset={handleReset}>
        <Routes>
          {/* Public routes for authentication */}
          <Route element={<DefaultLayout />}>
            <Route
              path="signin"
              element={<AuthenticationPage type={SIGN_IN} />}
            />
            <Route
              path="signup"
              element={<AuthenticationPage type={SIGN_UP} />}
            />
            <Route
              path="forgot-password"
              element={<AuthenticationPage type={FORGOT_PASSWORD} />}
            />
          </Route>
          {/* Authenticated registration routes with cart */}
          <Route element={<ProtectedRoute layout={DEFAULT_LAYOUT} />}>
            <Route path="" element={<RegistrationPage />} />
            <Route path="register" element={<RegistrationPage />} />
            <Route path="register/ccats" element={<RegistrationPage />} />
            <Route path="register/summer" element={<RegistrationPage />} />
            <Route path="checkout" element={<CheckoutPage />} />
          </Route>
          {/* Login to account dashboard */}
          <Route path="account" element={<AccountLayout />}>
            <Route
              path="signin"
              element={
                <AuthenticationPage
                  type={SIGN_IN}
                  redirectPath="/account/dashboard"
                />
              }
            />
            <Route
              path="signup"
              element={
                <AuthenticationPage
                  type={SIGN_UP}
                  redirectPath="/account/dashboard"
                />
              }
            />
            <Route
              path="forgot-password"
              element={
                <AuthenticationPage
                  type={FORGOT_PASSWORD}
                  redirectPath="/account/dashboard"
                />
              }
            />
          </Route>
          {/* Authenticated routes for account management */}
          <Route
            path="account"
            element={
              <ProtectedRoute layout={ACCOUNT_LAYOUT} redirectPath="/account" />
            }
          >
            <Route path="" element={<AccountPage tab="dashboard" />} />
            <Route path="edit" element={<EditAccountPage />} />
            {
              // map each account tab to its respective route
              tabsToArray().map((tab) => (
                <Route
                  key={tab.title}
                  path={tab.url}
                  element={<AccountPage tab={tab} />}
                />
              ))
            }
          </Route>
          {/* Masquerade route for account management */}
          <Route path="account/masquerade" element={<Masquerade />} />

          <Route element={<ProtectedRoute />}>
            <Route path="receipt/:id" element={<ReceiptPage />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
