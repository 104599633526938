import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { config, domUtils } from "../../helpers/payeezy";
import useScript from "../../hooks/useScript";
import PayeezyInput from "../UI/PayeezyInput";
import "./PaymentForm.css";
import { uiActions } from "../../store/ui/slice";
import TermsAndConditions from "./TermsAndConditions";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import Loader from "../UI/Loader";
import { Button } from "@mui/material";

const INITIAL_REG_SUBMIT_CONFIG = {
  disabled: true,
  text: "Register & Pay",
};

const INITIAL_NOREG_SUBMIT_CONFIG = {
  disabled: true,
  text: "Pay Now",
};

// payeezy form for collecting credit card info
const PaymentForm = ({
  isRegistering = false,
  totalPrice,
  onSubmit,
  onSubmitMsg,
  totalDiscount = 0,
}) => {
  const SUBMIT_CONFIG = isRegistering
    ? INITIAL_REG_SUBMIT_CONFIG
    : INITIAL_NOREG_SUBMIT_CONFIG;
  const paymentjsv2 = useScript(
    `https://lib.paymentjs.firstdata.com/${process.env.REACT_APP_PAYEEZY_ENV}/client-${process.env.REACT_APP_PAYEEZY_VERSION}.js`
  );
  const dispatch = useDispatch();
  const [isFormCreated, setIsFormCreated] = useState(false);
  const [isFormLoaded, setIsFormLoaded] = useState(false);
  const [submitConfig, setSubmitConfig] = useState(SUBMIT_CONFIG);
  const firstData = window.firstdata;

  const showTotalPrice = () => {
    if (totalDiscount > 0) {
      // TODO show this better
      return (
        <Typography align="center" fontWeight={700}>
          Total charged to card after ${totalDiscount} discount: $
          {totalPrice - totalDiscount}
        </Typography>
      );
    } else {
      return (
        <Typography align="center" fontWeight={700}>
          Total charged to card: ${totalPrice}
        </Typography>
      );
    }
  };

  useEffect(() => {
    let data = {}; // stores client token and nonce

    // initialize payeezy form after the library has loaded
    if (paymentjsv2 === "ready" && !isFormCreated) {
      // these utils might be useful later
      // const DomUtils = {
      //   getEl: (selector) => window.document.querySelector(selector),

      //   hasClass: (el, cssClass) => {
      //     if (el.classList) {
      //       return el.classList.contains(cssClass);
      //     }
      //     return !!el.className.match(new RegExp(`(\\s|^)${cssClass}(\\s|$)`));
      //   },

      //   removeClass: (el, cssClass) => {
      //     if (el.classList) {
      //       el.classList.remove(cssClass);
      //     } else if (DomUtils.hasClass(el, cssClass)) {
      //       const reg = new RegExp(`(\\s|^)${cssClass}(\\s|$)`);
      //       el.className = el.className.replace(reg, " ");
      //     }
      //   },

      //   addClass: (el, cssClass) => {
      //     if (el.classList) {
      //       el.classList.add(cssClass);
      //     } else if (!DomUtils.hasClass(el, cssClass)) {
      //       el.className += " " + cssClass;
      //     }
      //   },
      // };

      const submitButton = {
        enable: () => {
          setSubmitConfig({ ...submitConfig, disabled: false });
        },

        setSubmitState: () => {
          setSubmitConfig({
            ...submitConfig,
            disabled: true,
            text: "Processing...",
          });
        },

        removeSubmitState: () => {
          setSubmitConfig({
            ...submitConfig,
            disabled: false,
            text: SUBMIT_CONFIG.text,
          });
        },
      };

      // sends payeezy api request for client token and nonce
      const authorizeSession = (callback) => {
        dispatch(uiActions.setAlertInfo(onSubmitMsg));
        let request = new XMLHttpRequest();
        request.onload = () => {
          if (request.status >= 200 && request.status < 300) {
            const response = JSON.parse(request.responseText);
            const { clientToken, nonce } = response;
            data = { clientToken, nonce };
            callback(response);
          } else {
            dispatch(
              uiActions.setAlertError(
                "Failed to process payment. Please try another time."
              )
            );
            submitButton.removeSubmitState();
          }
          request = null;
        };

        // should be POST request?
        request.open(
          "GET",
          process.env.REACT_APP_API_URL + "authorize_session",
          true
        );
        request.send();
      };

      const payeezyHooks = {
        preFlowHook: authorizeSession,
      };

      // create event handlers for payeezy form error and success cases
      const onCreate = (paymentForm) => {
        const onSuccess = () => {
          submitButton.removeSubmitState();
          //console.log("Calling onSubmit from PaymentForm");
          onSubmit(data);
        };

        const onError = (error) => {
          let errorMessage =
            "Failed to process payment. Please try another time.";
          if (error.message === "form validation failed") {
            errorMessage = "Please fill out all fields.";
          }
          dispatch(uiActions.setAlertError(errorMessage));

          submitButton.removeSubmitState();
        };

        const form = domUtils.getEl("#payment-form");
        form.addEventListener("submit", (e) => {
          e.preventDefault();
          submitButton.setSubmitState();
          paymentForm.onSubmit(onSuccess, onError);
        });

        const ccFields =
          window.document.getElementsByClassName("payment-fields");
        for (let i = 0; i < ccFields.length; i++) {
          domUtils.removeClass(ccFields[i], "disabled");
        }

        setIsFormLoaded(true);
        submitButton.enable();
      };

      // create payeezy form using above config
      if (firstData) {
        const logger = (level, msg) => console.log(msg);
        firstData.createPaymentForm(config, payeezyHooks, onCreate, logger);
        setIsFormCreated(true);
      }
    }

    return () => {
      if (window.firstData) {
        delete window.firstdata;
        setIsFormLoaded(false);
        setIsFormCreated(false);
      }
    };
  }, [paymentjsv2]);

  return (
    <Box
      component="form"
      id="payment-form"
      justifyContent="center"
      alignItems="center"
    >
      {/* Hide payeezy form until api responds with iframe inputs */}
      <Box sx={{ display: isFormLoaded ? "block" : "none" }}>
        <PayeezyInput label="Cardholder Name" id="cc-name" data-cc-name />
        <PayeezyInput label="Card Number" id="cc-card" data-cc-card />
        <PayeezyInput label="Expiration Date" id="cc-exp" data-cc-exp />
        <PayeezyInput label="CVV" id="cc-cvv" data-cc-cvv />
      </Box>
      {/* Show loader until payeezy is done loading */}
      <Box
        justifyContent="center"
        sx={{ display: isFormLoaded ? "none" : "flex" }}
      >
        <Loader />
      </Box>
      <TermsAndConditions isRegistering={isRegistering} />
      <Box p={1}>{showTotalPrice()}</Box>
      <Button
        type="submit"
        variant="contained"
        id="submit-button"
        disabled={submitConfig.disabled}
        data-submit-btn
        fullWidth
      >
        {submitConfig.text}
      </Button>
      <input type="hidden" name="clientToken" id="clientToken" value="" />
      <input type="hidden" name="nonce" id="nonce" value="" />
    </Box>
  );
};

PaymentForm.propTypes = {
  totalPrice: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PaymentForm;
