import EditAccountForm from "./EditAccountForm";
import React, { useEffect, useState } from "react";
import useCustomerInfo from "../../../hooks/useCustomerInfo";
import { useUpdateAccountMutation } from "../../../store/account/api";
import useStateValidation from "../../../hooks/useStateValidation";
import { editAccountFormValidations } from "../../../helpers/validations";
import { EDIT_ACCOUNT_FORM } from "../../../store/account/constants";
import { Avatar } from "@mui/material";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
import Loader from "../../UI/Loader";
import { useDispatch } from "react-redux";
import { uiActions } from "../../../store/ui/slice";
import useSmarty from "../../../hooks/useSmarty";

const ZIP_MAX_LENGTH = 5;

const EditAccountPage = () => {
  const dispatch = useDispatch();
  const {
    customerInfo,
    customerError,
    isFetchingCustomerInfo,
    isSuccessCustomerInfo,
    isUninitializedCustomerInfo,
  } = useCustomerInfo();
  const [
    updateAccount,
    {
      data: updateData,
      isLoading: isLoadingUpdate,
      isSuccess: isSuccessUpdate,
      error: updateError,
      isError: isErrorUpdate,
    },
  ] = useUpdateAccountMutation();

  const [form, setForm] = useState(EDIT_ACCOUNT_FORM);
  const [errors, setErrors] = useState(EDIT_ACCOUNT_FORM);

  const validateForm = useStateValidation(
    editAccountFormValidations,
    setErrors
  );

  const {
    isError: isErrorSmarty,
    error: smartyError,
    isSuccess: isSuccessSmarty,
    data: smartyData,
    lookupAddress,
  } = useSmarty();

  const checkAddress = () => {
    if (form.street && form.city && form.state && form.zip) {
      lookupAddress(form);
    }
  };

  useEffect(() => {
    if (isSuccessSmarty) {
      //console.log(smartyData);
    } else if (isErrorSmarty) {
      console.error(smartyError);
    }
  }, [isSuccessSmarty, smartyData, isErrorSmarty, smartyError]);

  // populate form with data from server
  useEffect(() => {
    if (isSuccessCustomerInfo) {
      const { id, fullName, ...formData } = customerInfo; // strip out id and fullName
      setForm(formData);
    }
  }, [isSuccessCustomerInfo, customerInfo]);

  // handle update error
  useEffect(() => {
    if (isErrorUpdate) {
      console.error(updateError);
      dispatch(uiActions.setAlertError("Failed to save changes"));
    }
  }, [isErrorUpdate, updateError]);

  // handle update success
  useEffect(() => {
    if (isSuccessUpdate) {
      //console.log(updateData);
      dispatch(uiActions.setAlertSuccess("Account changes saved"));
    }
  }, [isSuccessUpdate, updateData]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "zip" && value.length > ZIP_MAX_LENGTH) return;
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { newErrors } = validateForm(form);
    const notValidFields = Object.keys(newErrors);
    const validData = {};
    for (let [key, value] of Object.entries(form)) {
      if (!notValidFields.includes(key)) {
        validData[key] = value;
      }
    }
    const { email, ...updateData } = validData; // strip email from update data
    if (Object.keys(updateData).length > 0) {
      updateAccount(updateData);
    }
  };

  const renderContent = () => {
    if (isUninitializedCustomerInfo) {
      return <Loader />;
    }
    if (isSuccessCustomerInfo) {
      const firstName = form.firstName;
      const lastName = form.lastName;

      return (
        <>
          {/* HEADER */}
          <Avatar
            sx={{
              width: 100,
              height: 100,
              bgcolor: "#B94700",
              fontSize: "30pt",
            }}
          >
            {firstName && lastName ? firstName[0] + lastName[0] : "YA"}
          </Avatar>
          <Typography variant="h4" padding={2}>
            {firstName && lastName
              ? firstName + " " + lastName
              : "Your Account"}
          </Typography>
          {/* FORM */}
          <EditAccountForm
            form={form}
            errors={errors}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onVerifyAddress={checkAddress}
            isLoading={isFetchingCustomerInfo || isLoadingUpdate}
          />
        </>
      );
    }
  };

  if (customerError) return <div>Error</div>;

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      padding={{ xs: 1, sm: 2 }}
    >
      {renderContent()}
    </Stack>
  );
};

export default EditAccountPage;
